import xhr from './xhr/';
/**
 * 图表分析相关的 API
 */
class ChartService {
	// 预警统计问题汇总
	getChartWarning(data) {
		return xhr({
			method: 'get',
			url: '/warning/chart',
			params: data,
		});
	}

	// 服务数据统计
	getChartServices(data) {
		return xhr({
			method: 'get',
			url: '/services/chart',
			params: data,
		});
	}

	// 服务数据统计
	getChartServicesBranch(data) {
		return xhr({
			method: 'get',
			url: '/services_branch/chart',
			params: data,
		});
	}

	// 图表统计基础数据日期等级建档量
	getChartDate(data) {
		return xhr({
			method: 'get',
			url: '/date/grade',
			params: data,
		});
	}

	// 图表统计基础数据年龄等级
	getChartAge(data) {
		return xhr({
			method: 'get',
			url: '/age/grade',
			params: data,
		});
	}

	// 图表统计基础数据年龄等级
	getChartSick(data) {
		return xhr({
			method: 'get',
			url: '/sick/grade',
			params: data,
		});
	}

	// 图表统计基础数据文化程度等级
	getChartEducation(data) {
		return xhr({
			method: 'get',
			url: '/education/grade',
			params: data,
		});
	}

	// bmi基础数据文化程度等级
	getBasicBmi(data) {
		return xhr({
			method: 'get',
			url: '/get/bmi',
			params: data,
		});
	}

	// 图表统计基础数据日期等级建档量
	getChartBasic(data) {
		return xhr({
			method: 'get',
			url: '/base/chart',
			params: data,
		});
	}

	// 全部问题数据
	getProblemAll(data) {
		return xhr({
			method: 'get',
			url: '/problem/chart',
			params: data,
		});
	}

	// 单个问题数据
	getProblemOne(data) {
		return xhr({
			method: 'get',
			url: '/one_problem/chart',
			params: data,
		});
	}

	// 训练数据全国前十名上传数据条数
	getChartTrain(data) {
		return xhr({
			method: 'get',
			url: '/trans/chart',
			params: data,
		});
	}

	// 训练数据数据条件过滤统计 最大最小中位数，平均数
	getTrainCondition(data) {
		return xhr({
			method: 'get',
			url: '/trans/condition',
			params: data,
		});
	}

	getChartPlan(data) {
		return xhr({
			method: 'get',
			url: '/plan/chart',
			params: data,
		});
	}

	// 训练数据店面上传数据的总和
	getTrainBranch(data) {
		return xhr({
			method: 'get',
			url: '/trans/branch',
			params: data,
		});
	}

	// 运动数据全国前十名上传数据条数
	getChartSport(data) {
		return xhr({
			method: 'get',
			url: '/sport/chart',
			params: data,
		});
	}

	// 运动数据条件过滤统计 最大最小中位数，平均数
	getSportCondition(data) {
		return xhr({
			method: 'get',
			url: '/sport/condition',
			params: data,
		});
	}

	// 运动数据店面上传数据的总和
	getSportBranch(data) {
		return xhr({
			method: 'get',
			url: '/sport/branch',
			params: data,
		});
	}

	// 获取等级列表
	getGradeList(data) {
		return xhr({
			method: 'get',
			url: '/grade/list',
			params: data,
		});
	}

	// 检测数据全国前十名上传数据条数
	getChartMonitor(data) {
		return xhr({
			method: 'get',
			url: '/monitor/chart',
			params: data,
		});
	}

	// 监测数据数据数据条件过滤统计 最大最小中位数，平均数
	getMonitorCondition(data) {
		return xhr({
			method: 'get',
			url: '/monitor/condition',
			params: data,
		});
	}

	// 监测数据各项没上传比例
	getMonitorBranch(data) {
		return xhr({
			method: 'get',
			url: '/monitor/branch',
			params: data,
		});
	}

	// 促进图表统计问题汇总
	getChartBoost(data) {
		return xhr({
			method: 'get',
			url: '/boost/chart',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new ChartService();
